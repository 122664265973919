<template>
    <div id="inspire">
        <v-app-bar
      app
      color="white"
      flat
    >
     <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
     

      <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
        size="32"
      ></v-avatar>
      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1"
      >
        <v-tab
          v-for="link in links"
          :key="link"
          :to="link.destination"
        >
          {{ link.title }}
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
    >
    <v-list>


        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Einführung in das <br>Recht der digitalen <br>Gesellschaft
            </v-list-item-title>
            <v-list-item-subtitle>POL10101</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    
     <v-list nav dense>
      <v-subheader>FORUM</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items4"
          :key="i"
          :to="item.destination"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

      <v-list nav dense>
      <v-subheader>GESETZESTEXTE</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      
        <v-list-item
          v-for="(item, i) in items5"
          :key="i"
          :href="item.destination"
          target="_blank"
        >
        
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>

    <div class="pt-10 pl-5 text-body-2">
    Hilfe? Wende dich an elaw@gov.tum.de
    </div>
    <div class="pt-3 pl-5 pb-5 text-body-2">
    Feedback sowie technische Probleme gerne an michael.bressler@tum.de
    </div>
    <div class="px-5 text-body-2">
      <a href="https://www.tum-cdps.de/kontakt/" target="_blanc">Impressum</a>
    </div>
    <div class="px-5 text-body-2">
      <a href="https://www.tum-cdps.de/datenschutzerklaerung/" target="_blanc">Datenschutzerklärung</a>
    </div>
    </v-navigation-drawer>
    </div>
</template>

<script>
export default {
  name: 'AppBar',
  data: () => ({
      drawer: true,
      group: null,
      links: [
        {
            title: 'Vorlesungen & Übungen',
            destination: '/'
        },
        {
            title: 'Skript',
            destination: '/script'
        },
      ],
      items: [
        { text: 'Notepads', icon: 'mdi-notebook' },
        { text: 'Dateien', icon: 'mdi-folder-multiple' },
      ],
      items2: [
        { text: 'Kommende Veranstaltung', icon: 'mdi-clock' },
        { text: 'Neueste VOD starten', icon: 'mdi-flag' },
        { text: 'Skript weiterlesen', icon: 'mdi-flag' },
        { text: 'Aktuelle Übungsaufgabe', icon: 'mdi-account' },
        { text: 'Mitschreibe-Assistent', icon: 'mdi-account', destination: '/mitschreiben' },
      ],
      items3: [
         { text: 'Einstellungen', icon: 'mdi-clock' },
      ],
      items4: [
        { text: 'Ankündigungen', icon: 'mdi-bell', destination: '/announcements' },
        { text: 'Q&A', icon: 'mdi-chat-question', destination: '/qa' },
      ],
      items5: [
        { text: 'Gesetze im Internet', icon: 'mdi-open-in-new', destination: 'https://www.gesetze-im-internet.de/index.html' },
        { text: 'Grundgesetz (GG)', icon: 'mdi-file-pdf-box', destination: 'https://www.tum-cdps.de/wp-content/uploads/GG.pdf' },
        { text: 'Bayerische Verfassung (BV)', icon: 'mdi-file-pdf-box', destination: 'https://www.tum-cdps.de/wp-content/uploads/BV.pdf' },
        { text: 'BayVwVfG', icon: 'mdi-file-pdf-box', destination: 'https://www.tum-cdps.de/wp-content/uploads/BayVwVfG.pdf' },
      ],
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>

<style scoped>
.stick {
  position: sticky;
  top:  100px;
  z-index:  2;
}
</style>