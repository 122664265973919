<template>

  <v-main class="white">

    <v-container fluid>
      <v-sheet rounded="lg" min-height="568" max-width="1300" class="center">
        <v-container @click="show = !show">
          <v-row dense>
            <v-col cols="12">


              <a href="https://www.tum-cdps.de/wp-content/uploads/Klausur.pdf" style="text-decoration: inherit;" target="_blank">
              <v-card color="green" dark class="elevation-0 mb-4">

<v-row dense>
  <v-col cols="1">
    <v-icon large class="pt-3 pl-5">
      mdi-file-pdf-box
    </v-icon>
  </v-col>
  <v-col cols="8">
    <v-card-title class="text-h5 pb-0">
      <p> Merkblatt zur Klausur</p>
    </v-card-title>




  </v-col>
  <v-col cols="3">
    <v-card-text align="right">
      01.03.2023
    </v-card-text>






  </v-col>
</v-row>


</v-card>
</a>


              <v-card color="blue darken-1" dark class="elevation-0">

                <v-row dense>
                  <v-col cols="9">
                    <v-card-title class="text-h5">
                      <p> Informationen zur Klausur</p>
                    </v-card-title>

                    <v-card-subtitle class="white--text">Liebe Studierende,
                    </v-card-subtitle>




                  </v-col>
                  <v-col cols="3">
                    <v-card-text align="right">
                      07.02.2023
                    </v-card-text>

                    <div align="right" class="pr-2">
                      <v-btn icon>
                        <v-icon>{{ show? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                    </div>




                  </v-col>
                </v-row>

                <v-expand-transition>
                  <div v-show="show">

                    <v-card-text class="pt-2 pb-2">

                      <p>hier finden Sie die gesonderten Folien mit den Informationen zur Klausur.</p>

                      <p><a
                          v-bind:href="klausur_Info_Folien"
                          style="text-decoration: inherit;" target="_blank">
                          <v-btn :loading="loading" small depressed class="pl-1 ml-5"
                            @click="loader = 'loading'"><v-icon right color="grey darken-2">
                              mdi-file-pdf-box
                            </v-icon>
                            <div class="pl-2">Folien</div>
                          </v-btn></a></p>

                      <p>Beste Grüße<br>Ihr Lehrstuhl-Team</p>
                    </v-card-text>

                    <v-card-actions class=pt-0>
                      <v-spacer></v-spacer>
                      <v-btn text to="/announcements">
                        MEHR
                      </v-btn>
                    </v-card-actions>

                  </div>
                </v-expand-transition>

              </v-card>
            </v-col>

          </v-row>
        </v-container>




        <v-container class="pt-0">

          <v-row dense>
            <v-col cols="9">

              <v-chip-group class="pb-0" active-class="primary--text" mandatory>
                <v-chip outlined v-model="vl_filter">
                  Vorlesungen Dienstag 18:30
                </v-chip>
                <v-chip outlined v-model="ue1_filter">
                  Übungen Donnerstag 11:00
                </v-chip>
                <v-chip outlined v-model="ue2_filter">
                  Übungen Donnerstag 18:30
                </v-chip>
              </v-chip-group>

            </v-col>
            <v-col cols="3" class="text-right">
              <v-btn text to="/announcements" class="grey--text text--darken-1">
                Alle Ankündigungen
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="mt-0">
            <v-col cols="12">


              <v-card v-for="(video, i) in filteredUnits" :key="i" class="elevation-0 pt-1 pb-2" max-height="200">
                <div class="d-flex flex-no-wrap">



                  <v-img v-if="hover" class="white--text align-end" max-height="138px" max-width="245px"
                    src="https://www.onlinesolutionsgroup.de/wp-content/uploads/giphy.gif">
                    <v-card-text align="right" class="pb-1 pr-2">
                      <div style="elevation-24">{{ video.duration }}</div>
                    </v-card-text>
                  </v-img>


                  <v-img v-else :src="video.image" class="white--text align-end" max-height="138px" max-width="245px">
                    <v-card-text align="right" class="pb-1 pr-2">
                      <div style="elevation-24"></div>
                    </v-card-text>
                  </v-img>

                  <div>
                    <v-card-text style="font-size:1.2em; font-weight:400;" class="pt-2 pb-1">{{
                      video.title
                    }}</v-card-text>
                    <v-card-subtitle class="pb-2 pt-0">
                      {{ video.subtitle }} {{ video.date }}
                    </v-card-subtitle>
                    <v-card-text class="text--primary">
                      <div>{{ video.description }}</div>
                      <div class="mt-2">


                        <a v-bind:href="video.locationlink" style="text-decoration: inherit;" target="_blank">
                          <v-btn small depressed :color="video.location_color" class="pl-0">
                            <v-icon :color="video.location_iconcolor" class="">
                              mdi-map-marker
                            </v-icon>
                            <div class="pl-1">{{ video.location }}</div>
                          </v-btn></a>


                        <a v-if="video.disabled == false" v-bind:href="video.file" style="text-decoration: inherit;"
                          target="_blank">
                          <v-btn :loading="loading" :disabled="video.disabled" small depressed class="pl-1 ml-5"
                            @click="loader = 'loading'"><v-icon right color="grey darken-2">
                              mdi-file-pdf-box
                            </v-icon>
                            <div class="pl-2">Folien</div>
                          </v-btn></a>



                        <a v-if="video.content1_disabled == false" v-bind:href="video.content1"
                          style="text-decoration: inherit;" :target="video.content1_target">
                          <v-btn :loading="loading" :disabled="video.content1_disabled" small depressed
                            class="pl-1 ml-5" @click="loader = 'loading'"><v-icon right color="grey darken-2">
                              {{ video.content1_icon }}
                            </v-icon>
                            <div class="pl-2">{{ video.content1_name }}</div>
                          </v-btn></a>

                        <a v-if="video.content2_disabled == false" v-bind:href="video.content2"
                          style="text-decoration: inherit;" :target="video.content2_target">
                          <v-btn :loading="loading" :disabled="video.content2_disabled" small depressed
                            class="pl-1 ml-5" @click="loader = 'loading'"><v-icon right color="grey darken-2">
                              {{ video.content2_icon }}
                            </v-icon>
                            <div class="pl-2">{{ video.content2_name }}</div>
                          </v-btn></a>






                      </div>
                    </v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!--  -->
      </v-sheet>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    links: [
      'Vorlesungen & Übungen',
      'Skript',
      'Übungsaufgaben',
    ],
    show: false,
    vl_filter: true,
    ue1_filter: false,
    ue2_filter: false,
    selectedType: 'Vorlesung',
    klausur_Info_Folien: 'https://www.tum-cdps.de/wp-content/uploads/Folien_Infos_Klausur_Einfuehrung-Recht.pdf',
    vorlesungen: [
      {
        title: '1. Vorlesung',
        subtitle: '',
        date: '25.10.2022 18:30 Uhr',
        description: 'Organisatorisches, Einführung: Begriff und Funktionen des Rechts',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL1.pdf',
        disabled: false,
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '2. Vorlesung',
        subtitle: '',
        date: '08.11.2022  18:30 Uhr',
        description: 'Grundlagen: Rechtsquellen, Rechtsgebiete, Normenhierarchie, Rechtsdogmatik',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL2.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '3. Vorlesung',
        subtitle: '',
        date: '15.11.2022  18:30 Uhr',
        description: 'Staat und Verfassung',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL3.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '4. Vorlesung',
        subtitle: '',
        date: '22.11.2022  18:30 Uhr',
        description: 'Staatsziele und Staatsstrukturprinzipien, Gesetzgebungskompetenz',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL4.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '5. Vorlesung',
        subtitle: '',
        date: '30.11.2022 18:30 Uhr',
        description: 'Das parlamentarische Gesetzgebungsverfahren',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL5.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '6. Vorlesung (entfallen)',
        subtitle: '',
        date: '06.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: true,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL6.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '7. Vorlesung',
        subtitle: '',
        date: '14.12.2022 18:30 Uhr (Neuer Termin)',
        description: 'Staatsrecht: Grundrechte',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL7.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '8. Vorlesung',
        subtitle: '',
        date: '20.12.2022 18:30 Uhr',
        description: 'Staatsrecht: Grundrechte',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL7.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '9. Vorlesung',
        subtitle: '',
        date: '10.01.2023 18:30 Uhr',
        description: 'Grundrechte (Fortsetzung) & Verfassungsrechtliche Rechtsbehelfe',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL9.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '10. Vorlesung',
        subtitle: '',
        date: '17.01.2023 18:30 Uhr',
        description: 'Verwaltungsrecht',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL10.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '11. Vorlesung',
        subtitle: '',
        date: '24.01.2023 18:30 Uhr',
        description: 'Verwaltungsrecht | Der Verwaltungsakt',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL11.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '12. Vorlesung',
        subtitle: '',
        date: '31.01.2023 18:30 Uhr',
        description: 'Weitere Handlungsformen der Verwaltung | Verwaltungsprozessrecht',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL12.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '13. Vorlesung',
        subtitle: '',
        date: '07.02.2023 18:30 Uhr',
        description: 'Grundzüge des Europarechts (Integration ins Bundesrecht, Grundrechtecharta, Grundfreiheiten, Organe der EU, Binnenmarkt, Sekundärrecht)',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL13.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: 'Klausur',
        subtitle: '',
        date: '03.03.2023 11:15 -  12:45 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Vorlesung',
        image: 'https://www.tum-cdps.de/wp-content/uploads/lecturehall.jpg',
        disabled: true,
        file: 'https://www.tum-cdps.de/wp-content/uploads/VL13.pdf',
        location: "H.103",
        locationlink: "https://nav.tum.de/room/2910.01.103",
        location_color: "white",
        location_iconcolor: "warning",
        content1_disabled: false,
        content1_name: "Merkblatt Klausur",
        content1_icon: "mdi-file-pdf-box",
        content1: 'https://www.tum-cdps.de/wp-content/uploads/Klausur.pdf',
      },

    ],
    uebungen1: [
      {
        title: '1. Übung',
        subtitle: '',
        date: '26.10.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        content1_disabled: false,
        content1_name: "Wordcloud",
        content1_icon: "mdi-image",
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE1.pdf',
        content1: 'https://learn.elaw.team/#/ue1wordcloud',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '2. Übung',
        subtitle: '',
        date: '02.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        content1_disabled: false,
        content1_name: 'BayFEV inkl. Begründung',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content2_disabled: false,
        content2_name: 'Miro-board',
        content2_icon: 'mdi-human-male-board',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE2.pdf',
        content1: 'https://www.tum-cdps.de/wp-content/uploads/BayFEV-mit-Begru%CC%88ndung.pdf',
        content2: 'https://learn.elaw.team/#/ue2miro',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '3. Übung',
        subtitle: '',
        date: '09.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE3.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '4. Übung',
        subtitle: '',
        date: '16.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE4.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '5. Übung',
        subtitle: '',
        date: '23.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE5.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '6. Übung',
        subtitle: '',
        date: '29.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE6.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '7. Übung (entfallen)',
        subtitle: '',
        date: '07.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: true,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
        location: "Hörsaal 2370",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=2370@0503&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '8. Übung',
        subtitle: '',
        date: '15.12.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
        location: "Zoom (Meeting-ID: 624 2068 7973 , PW: 293345)",
        locationlink: "https://tum-conf.zoom.us/j/62420687973?pwd=VXdFcG5XcnpJdU5qVGRMNk80MVpLUT09",
        location_color: "warning",
        location_iconcolor: "white"
      },
      {
        title: '9. Übung',
        subtitle: '',
        date: '22.12.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE8.pdf',
        location: "Zoom (Meeting-ID: 624 2068 7973 , PW: 293345)",
        locationlink: "https://tum-conf.zoom.us/j/62420687973?pwd=VXdFcG5XcnpJdU5qVGRMNk80MVpLUT09",
        location_color: "warning",
        location_iconcolor: "white"
      },
      {
        title: '10. Übung',
        subtitle: '',
        date: '12.01.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE10.pdf',
        location: "Zoom (Meeting-ID: 624 2068 7973 , PW: 293345)",
        locationlink: "https://tum-conf.zoom.us/j/62420687973?pwd=VXdFcG5XcnpJdU5qVGRMNk80MVpLUT09",
        location_color: "warning",
        location_iconcolor: "white"
      },
      {
        title: '11. Übung',
        subtitle: '',
        date: '19.01.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE11.pdf',
        location: "Seminarraum H.103",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.103@2910&disable_decoration=yes",
        location_color: "warning",
        location_iconcolor: "white",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/Uebung_Einfuehrung-Recht_Einheit_10_Sachverhalt.pdf"
      },
      {
        title: '12. Übung',
        subtitle: '',
        date: '26.01.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE12.pdf',
        location: "Seminarraum H.103",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.103@2910&disable_decoration=yes",
        location_color: "warning",
        location_iconcolor: "white",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/SV_Uebung_Einfuehrung-Recht_Eineit-11.pdf"
      },
      {
        title: '13. Übung',
        subtitle: '',
        date: '02.02.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE13.pdf',
        location: "Seminarraum H.103",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.103@2910&disable_decoration=yes",
        location_color: "warning",
        location_iconcolor: "white",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/SV_Uebung_Einfuehrung-Recht_Einheit_12.pdf"
      },
      {
        title: '14. Übung',
        subtitle: '',
        date: '09.02.2022 11:00 Uhr (Neuer Termin)',
        description: '',
        duration: '1:30:00',
        type: 'Übung1',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum2.jpg',
        disabled: false,
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE14.pdf',
        location: "Seminarraum H.103",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.103@2910&disable_decoration=yes",
        location_color: "warning",
        location_iconcolor: "white"
      },
    ],
    uebungen2: [
      {
        title: '1. Übung',
        subtitle: '',
        date: '27.10.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE1.pdf',
        content2: 'https://www.tum-cdps.de/wp-content/uploads/BayFEV-mit-Begru%CC%88ndung.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '2. Übung',
        subtitle: '',
        date: '03.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE2.pdf',
        disabled: false,
        content1_disabled: false,
        content1_name: 'BayFEV inkl. Begründung',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content2_disabled: false,
        content2_name: 'Miro-board',
        content2_icon: 'mdi-human-male-board',
        content1: 'https://www.tum-cdps.de/wp-content/uploads/BayFEV-mit-Begru%CC%88ndung.pdf',
        content2: 'https://learn.elaw.team/#/ue2miro',
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '3. Übung',
        subtitle: '',
        date: '10.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE3.pdf',
        disabled: false,
        location: "H.004 (Erdgeschoss HfP)",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.004@2910&disable_decoration=yes",
        location_color: "warning",
        location_iconcolor: "white"
      },
      {
        title: '4. Übung',
        subtitle: '',
        date: '17.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE4.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '5. Übung',
        subtitle: '',
        date: '24.11.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE5.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '6. Übung',
        subtitle: '',
        date: '01.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE6.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '7. Übung (entfallen)',
        subtitle: '',
        date: '08.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
        disabled: true,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '8. Übung',
        subtitle: '',
        date: '15.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE7.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '9. Übung',
        subtitle: '',
        date: '22.12.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE8.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '10. Übung',
        subtitle: '',
        date: '12.01.2022 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE10.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
      {
        title: '11. Übung',
        subtitle: '',
        date: '19.01.2023 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE11.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/Uebung_Einfuehrung-Recht_Einheit_10_Sachverhalt.pdf"
      },
      {
        title: '12. Übung',
        subtitle: '',
        date: '26.01.2023 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE12.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/SV_Uebung_Einfuehrung-Recht_Eineit-11.pdf"
      },
      {
        title: '13. Übung',
        subtitle: '',
        date: '02.02.2023 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE13.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1",
        content1_disabled: false,
        content1_name: 'Sachverhalt',
        content1_icon: 'mdi-file-pdf-box',
        content1_target: "_blank",
        content1: "https://www.tum-cdps.de/wp-content/uploads/SV_Uebung_Einfuehrung-Recht_Einheit_12.pdf"
      },
      {
        title: '14. Übung',
        subtitle: '',
        date: '09.02.2023 18:30 Uhr',
        description: '',
        duration: '1:30:00',
        type: 'Übung2',
        image: 'https://www.tum-cdps.de/wp-content/uploads/seminarraum.jpg',
        file: 'https://www.tum-cdps.de/wp-content/uploads/UE14.pdf',
        disabled: false,
        location: "Seminarraum H.206",
        locationlink: "https://portal.mytum.de/displayRoomMap?roomid=H.206@2910&disable_decoration=yes",
        location_color: "white",
        location_iconcolor: "grey lighten-1"
      },
    ],
  }),
  computed: {
    filteredVideos: function () {
      return this.vorlesungen
    },
    filteredUnits: function () {
      if (this.vl_filter == true) {
        return this.vorlesungen
      }
      else if (this.ue1_filter == true) {
        return this.uebungen1
      }
      else if (this.ue2_filter == true) {
        return this.uebungen2
      }
      else {
        return this.vorlesungen
      }
    }
  },
}
</script>

<style scoped>
.center {
  margin: auto;
}
</style>